import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import LayoutBase from '../layouts/LayoutBase'

const ElCentro = ( ) => {

    

    return (
        <LayoutBase>

            <div className="container-fluid p-0">
                    <StaticImage
                        src="../images/slider/08.jpg"
                        className="d-block w-100 min-h-300"
                        alt="Servicios"
                        layout="fullWidth"
                    />
            </div>

            <main className="container py-5">
                <h1>El Centro</h1>
                <p>En el centro de Palma, paralelo a calle Blanquerna, se encuentran las modernas instalaciones del Centro Desarrollo y Lenguaje. 240 m2 distribuidos entre su luminosa sala de espera, baños adaptados a minusvñalidos y con cambiador para bebés, seis amplios despachos dotados de la climatización y materiales ideales para las sesiones y una amplia sala polivalente con iluminación natural que ofrece un versátil ambiente en el que se pueden desarrollar desde grupos de juego hasta charlas y conferencias.</p>
            </main>

            
            <section className="container pb-5">
                <h4 className="pb-4">A continuación te invito a una visita virtual</h4>
                <div className="row">

                    {/* Fracción columna */}
                    <div className="col-6 col-md-3 pb-3">
                        <StaticImage
                            className="onHoverHand"
                            data-bs-target="#exampleModal"
                            data-bs-toggle="modal"
                            src="../../assets/images/blog/01.jpg"
                            width="300"
                        />       

                        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered modal-xl">
                                <div className="modal-content">

                                    <div className="modal-body">
                                        <StaticImage
                                            className="onHoverHand"
                                            data-bs-target="#exampleModal"
                                            data-bs-toggle="modal"
                                            src="../images/slider/01.jpg"
                                            width="1200"
                                        />
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Fracción columna */}
                    <div className="col-6 col-md-3 pb-3">
                        <StaticImage
                            className="onHoverHand"
                            data-bs-target="#exampleModal"
                            data-bs-toggle="modal"
                            src="../../assets/images/blog/01.jpg"
                            width="300"
                        />       

                        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">

                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>

                                    <div className="modal-body">
                                        ...
                                    </div>

                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        <button type="button" className="btn btn-primary">Save changes</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Fracción columna */}
                    <div className="col-6 col-md-3 pb-3">
                        <StaticImage
                            className="onHoverHand"
                            data-bs-target="#exampleModal"
                            data-bs-toggle="modal"
                            src="../../assets/images/blog/01.jpg"
                            width="300"
                        />       

                        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">

                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>

                                    <div className="modal-body">
                                        ...
                                    </div>

                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        <button type="button" className="btn btn-primary">Save changes</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Fracción columna */}
                    <div className="col-6 col-md-3 pb-3">
                        <StaticImage
                            className="onHoverHand"
                            data-bs-target="#exampleModal"
                            data-bs-toggle="modal"
                            src="../../assets/images/blog/01.jpg"
                            width="300"
                        />       

                        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">

                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>

                                    <div className="modal-body">
                                        ...
                                    </div>

                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        <button type="button" className="btn btn-primary">Save changes</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>


                    
                    {/* Fracción columna */}
                    <div className="col-6 col-md-3 pb-3">
                        <StaticImage
                            className="onHoverHand"
                            data-bs-target="#exampleModal"
                            data-bs-toggle="modal"
                            src="../../assets/images/blog/01.jpg"
                            width="300"
                        />       

                        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered modal-xl">
                                <div className="modal-content">

                                    <div className="modal-body">
                                        <StaticImage
                                            className="onHoverHand"
                                            data-bs-target="#exampleModal"
                                            data-bs-toggle="modal"
                                            src="../images/slider/01.jpg"
                                            width="1200"
                                        />
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Fracción columna */}
                    <div className="col-6 col-md-3 pb-3">
                        <StaticImage
                            className="onHoverHand"
                            data-bs-target="#exampleModal"
                            data-bs-toggle="modal"
                            src="../../assets/images/blog/01.jpg"
                            width="300"
                        />       

                        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">

                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>

                                    <div className="modal-body">
                                        ...
                                    </div>

                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        <button type="button" className="btn btn-primary">Save changes</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Fracción columna */}
                    <div className="col-6 col-md-3 pb-3">
                        <StaticImage
                            className="onHoverHand"
                            data-bs-target="#exampleModal"
                            data-bs-toggle="modal"
                            src="../../assets/images/blog/01.jpg"
                            width="300"
                        />       

                        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">

                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>

                                    <div className="modal-body">
                                        ...
                                    </div>

                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        <button type="button" className="btn btn-primary">Save changes</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Fracción columna */}
                    <div className="col-6 col-md-3 pb-3">
                        <StaticImage
                            className="onHoverHand"
                            data-bs-target="#exampleModal"
                            data-bs-toggle="modal"
                            src="../../assets/images/blog/01.jpg"
                            width="300"
                        />       

                        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">

                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>

                                    <div className="modal-body">
                                        ...
                                    </div>

                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        <button type="button" className="btn btn-primary">Save changes</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>



                    {/* Fracción columna */}
                    <div className="col-6 col-md-3 pb-3">
                        <StaticImage
                            className="onHoverHand"
                            data-bs-target="#exampleModal"
                            data-bs-toggle="modal"
                            src="../../assets/images/blog/01.jpg"
                            width="300"
                        />       

                        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered modal-xl">
                                <div className="modal-content">

                                    <div className="modal-body">
                                        <StaticImage
                                            className="onHoverHand"
                                            data-bs-target="#exampleModal"
                                            data-bs-toggle="modal"
                                            src="../images/slider/01.jpg"
                                            width="1200"
                                        />
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Fracción columna */}
                    <div className="col-6 col-md-3 pb-3">
                        <StaticImage
                            className="onHoverHand"
                            data-bs-target="#exampleModal"
                            data-bs-toggle="modal"
                            src="../../assets/images/blog/01.jpg"
                            width="300"
                        />       

                        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog">
                                <div className="modal-content">

                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>

                                    <div className="modal-body">
                                        ...
                                    </div>

                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        <button type="button" className="btn btn-primary">Save changes</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    

                    

                </div>
            </section>

        </LayoutBase>
    )
}

export default ElCentro
